import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/1nvhIK73adE">
    <SEO title="Sanctity of Human Life - Radical Lectures" />
  </Layout>
)

export default SermonPost
